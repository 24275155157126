// Here you can add other styles

.sidebar .nav-title {
  background-color: $gray-600;
  color: $white;
}

.sidebar .nav-link .nav-icon {
  font-size: 23px;
  margin: 0 1.3rem 0 -0.3rem;
}

.sidebar .nav-dropdown-toggle::before {
  width: 16px;
  height: 16px;
}

.sidebar .nav-dropdown-items .nav-link {
  padding-left: 40px;
  font-size: 0.75rem;
}

.sidebar .nav-dropdown-items .nav-link .nav-icon {
  font-size: 18px;
}

.sidebar .nav-dropdown-items .nav-icon {
  margin-right: 1.2rem;
}

.sidebar .nav-title-relatorios .badge {
  float: right;
  font-size: 0.7rem;
}

.sidebar .nav-item-relatorios .nav-link:hover,
.sidebar .nav-link.nav-item-relatorios:hover {
  background-color: $yellow;
  color: $black;
}

.sidebar .nav-item-relatorios .nav-link.active .nav-icon {
  color: $yellow;
}

.sidebar .nav-item-relatorios .nav-link.active:hover .nav-icon {
  color: $white;
}

.DayPickerInput {
  width: 100%;
}

.DayPickerInput input {
  @extend .form-control;
}

.btn {
  @extend .rounded;
}

.btn-group .btn {
  @extend .rounded-0;
}

.btn-group .btn:first-child {
  @extend .rounded-left;
}

.btn-group .btn:last-child {
  @extend .rounded-right;
}

input[type="checkbox"] {
  transform: scale(1.2);
}

@include media-breakpoint-down(sm) {
  .despesas-container {
    padding-left: 0;
    padding-right: 0;
  }

  .receitas-container {
    padding-left: 0;
    padding-right: 0;
  }
}

table.table tr.table-warning th {
  border-top-width: 2px;
}

.projetos-de-vida-header {
  background-image: url("/assets/img/projetos-de-vida-header.jpg");
  background-size: cover;
  background-position: 60% 50%;
  max-width: 100%;
  height: 400px;
  vertical-align: text-bottom;
  position: relative;
  margin-bottom: 30px;
}

.projetos-de-vida-header h1 {
  color: #fff;
  padding: 10px 0 0 50px;
  position: absolute;
  bottom: 100px;
}

.upload-de-extrato-header{
  background-image: url("/assets/img/projetos-de-vida-header.jpg");
  background-size: cover;
  background-position: 60% 50%;
  max-width: 100%;
  height: 400px;
  vertical-align: text-bottom;
  position: relative;
  margin-bottom: 30px;
}

.monetary {
  max-width: 180px;
}

.percent {
  max-width: 120px;
}

.required:after {
  content: " *";
  color: red;
  font-weight: bold;
}

.login-bg {
  background: url("/assets/img/login-bg.jpg") fixed no-repeat top center !important;
  background-size: cover !important;
}

.card-body-indices p {
  margin-bottom: 0.6rem;
}

.tbl_dre tbody {
  overflow: auto;
}

@include media-breakpoint-up(md) {
  .tbl_dre tr th:first-child,
  .tbl_dre tr td:first-child {
    position: sticky;
    left: 0;
  }
}

.lista-termos-secundaria > li {
  margin-bottom: 10px;
}

.shimmer {
  background: #777;
  margin-bottom: 20px;
}

.shimmer.w60 {
  width: 60%;
}

.shimmer.line {
  height: 30px;
}

.shimmer.textarea {
  height: 180px;
}

.shimmer.chart {
  height: 300px;
}

.shimmer.br {
  border-radius: 8px;
}

.shimmer.animate {
  animation: shimmer 2s infinite linear;
  background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
  background-size: 1000px 100%;
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

.legenda {
  display: flex;
  justify-content: center;
}

.legenda div {
  width: 40px;
  height: 12px;
  margin: 2px 5px 0 5px;
}

.legenda label {
  font-size: 12.5px;
  color: #444;
}

.legenda div:nth-child(1) {
  background: #1474C2;
}

.legenda div:nth-child(3) {
  background: #7FC97F;
}

.legenda div:nth-child(5) {
  background: #FF0029;
}

.nota-selecionada{
  background-color: #F7F7F7;
  border-color: #333;
}

.notas-wrapper{
  display: "flex";
  flex-wrap: wrap;
}

.notas-historico ul{
  list-style-type: none; 
  margin-top:20px;
  padding:0;
  max-height: 70vh;
  overflow-y: auto; 
}

.search-input-filter{
  width: 150px; 
  margin-right: 10px; 
  border-radius:7px;
}

@media (max-width: 1200px) {
  .search-input-filter{
    width:100px;
  }
}

@media only screen and (max-width: 767px) {
  .notas-historico ul {
    max-height: 56vh;
  }
}

.vertical-text{
  writing-mode: vertical-rl;  /* ou vertical-rl dependendo da sua preferência */
  transform: rotate(180deg);

}
.block-style{
  border: 1px solid #ccc;
  text-align: center;
  min-width: 60px;
  max-width: 60px;
  height: 50px;
}

/* TestePerfilResults.css */
.progress-container {
  height: 30px;
  width: 100%;
  background-color: #e6e6e6;
  border: 1px solid #e6e6e6;
  position: relative;
}

.progress-bar {
  height: 100%;
  background: linear-gradient(to right, #ff6666, #ff6666);
}

.chartpie-container {
  display: flex;  
  width: 30%;
  flex-direction: column;
  justify-content: right;
  align-items: center;
  margin-top: 20px;
}

.rounded-modal > .modal-content{
  border-radius: 20px !important;
}

.modal-header > h5{
  width: 100%;
}

.wrap-chart-div1{
  width: 70%;
}


@media (max-width: 768px) {
  .chartpie-container {
    width: 100%;
  }
  
}

@media (max-width:1140px){
  .chartpie-container {
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .wrap-chart-div1{
    width: 100%;
  }
  
}

.date-carousel-despesas{
  width: 60%;
  justify-content: flex-end;
  display: flex;
}

.filters-despesas-container{
  width: 40%;
  justify-content: flex-end;
  display: flex;
}

@media (max-width: 600px) {
  .date-carousel-despesas {
    width: 100%;
    justify-content: center;
  }

  .filters-despesas-container{
    width: 100%;
    justify-content: flex-end;
  }
}


.vertical-text {
  text-align: center;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  font-weight:900;
  padding: 8px;
  width: 20px;
}

.table-items {
  width: 20px;
}

.limit-text-line{
  margin-right: 5px;
  overflow: hidden;  
  text-overflow: ellipsis;  
  display: inline-block;
  max-width: 450px;

  @media screen and (max-width: 1200px) {
    max-width: 270px;
  }
  
  @media screen and (max-width: 768px) {
    max-width: 200px;
  }
}

.button-adm-back {
  position: absolute;
  right: 10%;
  z-index: 999;
  padding: 7px 20px;
  /* padding padrão */
  top: 67px;
  background-color: #036091;
  color: white;
  border-color: #036091;
}

@media (max-width: 450px) {
  .button-adm-back {
    padding: 7px 0;
  }
}

.header-container {
  position: sticky;
  top: 0;
}

.scrollable-content {
  overflow-y: auto;
  max-height: calc(100vh);
}
